/*------------------------------------------------------------------
[Main Stylesheet]

Project     : Jubzi Pos
Version     : 1.0
Author      : Muhammad Usama Mashkoor
Author URI  : https://usamamashkoor.github.io/prof/
-------------------------------------------------------------------*/

/*
==========================================
    Google fonts
==========================================
*/

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900');

/*------------------------------------------------------------------
[Table of contents]

1.  reset css
2.  home page
3.  kitchen page

-------------------------------------------------------------------*/

/*
==========================================
1.  reset css
==========================================
*/

*{
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Poppins',sans-serif;
  font-size: 18px;
  color: #282828;
  font-weight: 400;
  padding-right: 0px !important;
  overflow-y: scroll;
}
a,a:active,a:focus,a:hover{
  text-decoration: none;
  -webkit-transition: all .4s ease-out;
  -moz-transition: all .4s ease-out;
  -ms-transition: all .4s ease-out;
  -o-transition: all .4s ease-out;
}
h1,h2,h3,h4,h5,h6,p,ul,hr {
  padding: 0; 
  margin: 0;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins',sans-serif;
}
ul li {
  list-style: none;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] {
  -moz-appearance:textfield;
}
select{
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  /* background-image: url('../img/icons/menu-drop.png'); */
  background-position: center right 15px;
  background-repeat: no-repeat;
  min-height: 42px;
  font-size: 15px;
  padding-right: 40px !important;
}

/*
==========================================
2.  home page
==========================================
*/

.body-bg {
  background-image: url('../img/bg.jpg');
  /* background-image: url('../img/bg.jpg'); */
  background-position: center;
  background-size: cover;
}

.homepage-main {
  padding-top: 10px;
  min-height: 1024px;
  /* height: 1024px; */
  /* padding-top: 50px; */
}
.btn {
  min-height: 55px;
  /* height: 55px; */
  text-transform: uppercase;
  font-size: 16px;
  /* font-size: 23.93px; */
  /* border: 1px solid #fff; */
  border: none;
}
.food-menus-head li:not(:last-child) {
  /* padding-right: 20px; */
  padding-right: 5px;
}
.food-menus-head button {
  margin-bottom: 34px;
  /* width: 132px !important; */
}
.food-menus-head .btnKiosk{
  margin-right: 5px;
}
.btn-none-bg {
  border: 0;
  background-color: transparent;
}
.btn-danger {
  background-color: rgba(232,18,67,0.4);
}
.btn-primary {
  background-color: transparent;
}
.btn-primary:hover,.btn-primary:focus {
  background-color: transparent;
}
.food-menus-menu {
  padding-bottom: 18px;
}
.food-menus-menu button{
  margin-bottom: 16px;
}
.food-menus-menu .colCategory{
  /* padding-right: 9px;
  padding-left: 15px; */
  padding-right: 3px;
  padding-left: 3px;
}
.colCategory .btn {
  border: 1px solid #fff;
}
.food-menus-menu td:not(:last-child) {
  padding-right: 16px;
}
.food-menus-menu td {
  padding-bottom: 16px;
}
.food-menus-menu td:last-child {
  padding-right: 1px;
}
.food-menus-footer > .row{
  margin-left: -8px;
  margin-right: -8px;
}
.food-menus-footer > .row > div{
  /* padding-left: 8px; */
  /* padding-right: 8px; */
}
.food-menus-footer > .row > div > .row > div button {
  font-size: 16px;
}
.food-menus-footer > .row > div > .row > div:last-child{
  /* padding-left: 8px; */
}
.food-menus-footer > .row > div > .row > div:first-child{
  /* padding-right: 8px; */
  padding-left: 0px;
}
.food-menus-footer > .row > div > .row.preFilledNotesRow > div.col{
  padding-left: 0px;
  padding-right: 15px;  
}
.food-menus-footer > .row > div > .row.preFilledNotesRow > div button{
  font-size: 12px;
}
.notPadCol{
  padding-right: 0px !important;
}
.food-menus-footer > .row > div > .row.ingredientTypeRow > div.col-2 {
  /* padding-right: 8px; */
  padding-left: 0px;
  padding-right: 15px;
}

.food-menus-footer .btn-circle.btn-xl {
  min-width: 70px;
  min-height: 70px;
  min-height: 55px;
  padding: 10px 16px;
  /* border-radius: 50%; */
  /* border-radius: 35px; */
  /* font-size: 24px; */
  font-size: 12px;
  line-height: 1.33;
  background-color: #64000f;
}

.food-menus-footer .btn-circle {
  min-width: 30px;
  min-height: 30px;
  padding: 6px 0px;
  /* border-radius: 15px; */
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

button.btn.btnPreWrittenNotes{
  padding-right: 15px;
  padding-left: 15px;
  font-size: 12px;
}
.btn-success {
  background-color: rgba(59,209,13,0.4);
}
.btn-warning {
  background-color: rgba(247,237,25,0.4);
  color: #fff;
}
.btn-secondary {
  background-color: rgba(25,77,247,0.4);
}
.btnPreWrittenNotes{
  background-color: #737171;
  color: #fff;
}
.btn-secondary:hover,.btn-secondary:focus {
  background-color: rgba(25,77,247,0.4);
}
.btnPreWrittenNotes:hover,.btnPreWrittenNotes:focus {
  background-color: #eee;
  color: #333;
}
.food-cart-head {
  /* height: 638px; */
  /* height: 400px; */
  overflow: hidden;
  padding: 12px 18px;
}
.food-cart-head:hover {
  overflow-y: scroll;
}
.food-cart-head .restaurantLogo {
  /* background: #bcb9b9;
  margin-left: -18px;
  margin-right: -18px; */
}
.rowNoPad{
  padding-right: 0px;
  padding-left: 0px;
}
.food-cart-head .btnTrashCart{
  background-color: #e9292a;
  border-radius: 25px;
  height: auto !important;
}
.food-cart-head .btnTrashCart img{
  width: 180px;
  height: 50px;

}
.food-info-head h6 {
  font-weight: 700;
  font-size: 19.58px;
}
.food-info-head .spanPickupMobileTime{
  font-size: 16.58px;
}
.food-info-head p {
  font-size: 13px;
  font-weight: 700;
}
.food-info-head {
  border-bottom: 1px solid #000;
  margin-bottom: 6px;
  padding-bottom: 6px;
}
.food-info-list p,.food-info-list div {
  font-size: 13px;
  /* font-size: 14.23px; */
  /* font-size: 15.23px; */
  line-height: 15.23px;
  padding-left: 10px;
}
.food-info-list p.ingredient {
  /* padding-bottom: 5px; */
}
.food-info-list span.position-absolute {
  right: 0;
  font-weight: 700;
  /* font-size: 13.05px; */
  font-size: 12px;
  /* top: 0; */
}
.food-info-list div.position-absolute {
  right: 0;
  font-weight: 700;
  /* font-size: 13.05px; */
  font-size: 12px;
  /* top: 0; */
}
.food-info-list h6 {
  font-size: 15.4px;
  /* font-size: 17.4px; */
  font-weight: 700;
}
.food-info-list li:not(:last-child) {
  margin-bottom: 5px;
  /* margin-bottom: 57px; */
}
.food-info-list h6 div.menu-item-name{
  width: 70%;
}
.food-info-list h6 div.menu-item-price{
  width: 30%;
  text-align: right;
}
.food-info-sublist h6 div.menu-item-combo-item-name{
  width: 70%;
  padding-left: 0px;
}
.food-info-sublist h6 div.menu-item-combo-item-price{
  width: 30%;
  text-align: right;
  top: 0;
}
.food-info-sublist {
  /* padding-top: 30px; */
  /* padding-left: 67px; */
  padding-top: 5px;
  padding-left: 10px;
}
.food-cart-total h6 {
  font-size: 13.05px;
  font-weight: 600;
}
.food-cart-total p {
  font-size: 13.05px;
}
h5 {
  font-weight: 700;
  font-size: 17.4px;
}
.react-confirm-alert-button-group{
  display: block !important;
  text-align: center;
}
.food-cart-calculator .form-control {
  /* height: 25px; */
  height: 55px;
  margin-bottom: 16px;
  font-size: 23.93px;
  color: #fff !important;
  font-weight: 400;
}
.food-cart-calculator .form-control::-webkit-input-placeholder { /* Edge */
  color: #fff;
}
.food-cart-calculator .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}
.food-cart-calculator .form-control::placeholder {
  color: #fff;
}
.food-cart-calculator .form-control,.food-cart-calculator button {
  background-color: rgba(255,255,255,0.5);
}
.food-cart-calculator button {
  /* font-size: 32.63px;
  line-height: 20px; */
  font-size: 23.63px;
  line-height: 0px;
  /* height: 30px; */
  height: 40px;
  font-weight: 700;
  margin-bottom: 5px !important;
}
.text-regular {
  font-weight: 400 !important;
}
.food-cart-calculator button.text-regular {
  font-size: 20px;
}
.food-cart-calculator .rowExtraPaymentButtons{
  margin-right: 0px;
  margin-left: 0px;
}
.food-cart-calculator .rowExtraPaymentButtons .btnRow{
  width: 73px;
}
.margin-right-5{
  margin-right: 5px;
}
.food-navigation p {
  /* font-size: 13.05px; */
  /*font-size: 11.05px;*/
  /* font-size: 12.05px; */
  font-size: 11px;
}
.btn-orange {
  background-color: #b66304;
}
.btn-pink {
  background-color: #9b3a35;
}
.btn-light-danger {
  background-color: #ca0d0b;
}
.btn-light-danger:hover{
  background-color: #ff0000;
}
.btn-light-danger.btnRefundActive {
  background-color: #ff0000;
}
.btn.btn-light-success.btnMistakeActive {
  background-color: #00ff28;
}
.btn-light-warning {
  background-color: #c79b06;
}
.btn-light-warning.btnDoneActive {
  background-color: #ffc400;
}
.btn-light-success {
  background-color: #1fa634;
}
.btn-light-blue {
  background-color: #3e3aa6;
}
.btn-blue {
  background-color: #0e077e;
}
.btn-muted {
  background-color: #535050;
}
.btn-light-danger {
  background-color: #8f1f04;
}
.btn-light-danger:hover{
  background-color: #c44729;
}
.btnColorLightGrey{
  color: #d6d2d2 !important;
}
.btnColorLightGreen {
  background-color: #1fa634 !important;
}
.DoneModalBody{
  padding: 0px;
  background-color: #8f1f04;
}
.DoneModalBtns button {
  border-radius: 0px;
  position: relative;
}
.DoneModalBtns button .lineImg{
  padding-left: 0px;
  position: absolute;
  left: 0px;
}
.DoneModalBtns .col{
  padding-left: 0px;
  padding-right: 0px;
}
.DoneModalBtns img{
  /* height: 30px; */
}
.CashModalBody{
  padding: 0px;
  /* background-color: #8f1f04; */
}
.row.CashModalBtns{
  margin-left: 0px;
  margin-right: 0px;
}
.CashModalBtns button {
  border-radius: 0px;
  position: relative;
}
.CashModalBtns button .lineImg{
  padding-left: 0px;
  position: absolute;
  left: 0px;
}
.CashModalBtns .col{
  padding-left: 0px;
  padding-right: 0px;
}
.CashModalBtns img{
  /* height: 30px; */
}
.pl-18{
  padding-left: 19px;
}
.pl-0{
  padding-left: 0px;
}
.pr-0{
  padding-right: 0px;
}
.kitchenOptionsModalBody{
  padding: 0px;
  /* background-color: #8f1f04; */
}
.row.kitchenOptionsModalBtns{
  margin-left: 0px;
  margin-right: 0px;
}
.kitchenOptionsModalBtns button {
  border-radius: 0px;
  position: relative;
  min-height: 350px;
}
.kitchenOptionsModalBtns button .lineImg{
  padding-left: 0px;
  position: absolute;
  left: 0px;
}
.kitchenOptionsModalBtns .col{
  padding-left: 0px;
  padding-right: 0px;
}
.kitchenOptionsModalBtns img{
  height: 100px;
}
.kitchenOptionsModalBtns .btnMadeIt{
  background-color:#0000ff;
}
.kitchenOptionsModalBtns .btnTrash{
  background-color:#e9292a;
}
.kitchenOptionsModalBtns .btnNeverMind{
  background-color:#4cc10c;
}
.kitchenOptionsModalBtns p{
  font-size: 20px;
}
/* .btnPutOnSide{
  font-size: 11px;
} */
.PosOrdersPage .btnPrint{
  background-color: #00ff28;
  cursor: pointer;
}
.PosOrdersPage .btnLoadMore{
  background-color: #00ff28;
  height:inherit;
  cursor: pointer;
}
.PosOrdersPage .no_result{
  margin-top: 30px;
  font-size: 30px;
}
.food-navigation > p {
  font-size: 23.93px;
  line-height: 20px;
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-bottom: 14px;
  text-align: left;
  padding-left: 12px;
}
.food-menus-footer .row .col:first-child > .btn {
  /*! width: 202px !important; */
}
.food-menus-footer .col > .btn {
  font-size: 18px;
}
.notification span {
  font-size: 14px;
  color: #fff;
  font-weight: 900;
  border: 2px solid #ea2e08;
  background-color: #ea2e08;
  border-radius: 25px;
  width: 25px;
  height: 25px;
  top: -9px;
  right: 20px;
}
.notificationKios span{
  font-size: 14px;
  color: #fff;
  font-weight: 900;
  border: 2px solid #ea2e08;
  background-color:#ea2e08;
  border-radius: 25px;
  width: 25px;
  height: 25px;
  top: -9px;
  right: 157px;
}
@-moz-document url-prefix() {
  .notification span { /* for firefox style */
    border: 3px solid #cc353a;
    top: 8.5px;
  }
}

.main_loader {
  text-align: center;
  margin-top: 100px;
  width: 100%;
}
/*
==========================================
3.  kitchen page
==========================================
*/


.kitchen-head button {
  font-size: 25px;
  font-weight: 700;
}
.kitchen-head .btn {
  /* height: 35px; */
}
.kitchen-head .btnMenuDropDownToggle{
  background-color: white;
  color: black;
}
.kitchen-item-head {
  height: auto;
  border: 4px solid #fff;
  padding: 0 34px;
  text-transform: uppercase;
  min-height: 62px;
}
.kitchen-item-head:hover {
  overflow-y: hidden;
}
.kitchen-item-box .row > div {
  padding: 0 !important;
}
.kitchen-item-head .food-info-head {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.kitchen-item-head p {
  font-weight: 400;
  font-size: 18px;
}
.kitchen-item-head p.CustomerName{
  color: #f0f902;
}
.kitchen-item-head p.deliveryType {
    color: #66a3ff;
}
.kitchen-item-head p.deliveryType.color-white {
  color: #fff;
}
.kitchen-item-body {
  /* height: 239px; */
  min-height: 239px;
  padding: 30px 20px;
  border: 4px solid #fff;
  border-top: 0;
}
.food-info-list {
}
.kitchen-item-head .text-warning {
  color: #f0f902 !important;
}
.kitchen-item-head p.ticket_no {
  color: #f0f902 !important;
  font-size: 12px;
}
.kitchen-item-body .text-warning {
  color: #f0f902 !important;
}
.kitchen-item-body p,.kitchen-item-body li {
  font-size: 18px;
}
.kitchen-item-body ul li > ul li:not(:last-child) {
  margin-bottom: 0;
}
.kitchen-item-body > ul > li:not(:last-child) {
  margin-bottom: 0;
}
.kitchen-item-body .food-info-sublist {
  padding-top: 0;
  /* padding-left: 40px; */
  padding-left: 20px;
}
.kitchen-item-head.orderLate{
  background-color: red;
  color:#fff;
  /* background:blue; */
	/* padding:5px 10px; */
	/* color:#fff; */
	/* border:none; */
	/* border-radius:5px; */
	
	animation-name: flash;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;

	/* //Firefox 1+ */
	-webkit-animation-name: flash;
	-webkit-animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;

	/* //Safari 3-4 */
	-moz-animation-name: flash;
	-moz-animation-duration: 1s;
	-moz-animation-timing-function: linear;
	-moz-animation-iteration-count: infinite;
}
.kitchen-item-head.orderLateCashedThrough{
  background-color: green;
  color:#fff;
  /* background:blue; */
	/* padding:5px 10px; */
	/* color:#fff; */
	/* border:none; */
	/* border-radius:5px; */
	
	animation-name: flash;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;

	/* //Firefox 1+ */
	-webkit-animation-name: flash;
	-webkit-animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;

	/* //Safari 3-4 */
	-moz-animation-name: flash;
	-moz-animation-duration: 1s;
	-moz-animation-timing-function: linear;
	-moz-animation-iteration-count: infinite;
}
.kitchen-item-head.notDoneOrderButCashedThroughClass{
  background-color: green;
  color:#fff;
  /* background:blue; */
	/* padding:5px 10px; */
	/* color:#fff; */
	/* border:none; */
	/* border-radius:5px; */
	
	animation-name: flash;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;

	/* //Firefox 1+ */
	-webkit-animation-name: flash;
	-webkit-animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;

	/* //Safari 3-4 */
	-moz-animation-name: flash;
	-moz-animation-duration: 1s;
	-moz-animation-timing-function: linear;
	-moz-animation-iteration-count: infinite;
}
.kitchen-item-head.orderMoreThan30Mins{
  /* background-color: purple; */
  background-color:#731673;
  
  color:#fff;
  /* background:blue; */
	/* padding:5px 10px; */
	/* color:#fff; */
	/* border:none; */
	/* border-radius:5px; */
	
	/* animation-name: flash;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite; */

	/* //Firefox 1+ */
	/* -webkit-animation-name: flash;
	-webkit-animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite; */

	/* //Safari 3-4 */
	/* -moz-animation-name: flash;
	-moz-animation-duration: 1s;
	-moz-animation-timing-function: linear;
	-moz-animation-iteration-count: infinite; */
}

.bg-danger {
  background-color: #b30e12 !important;
}
.text-gold {
  color: #b78524 !important;
}
.bg-dark {
  background-color: #000 !important;
}
.bg-light-grey{
  background-color: #c6c5c5 !important;
}
.btnSelectedPaymentMethod{
  /* background-color:black !important ; */
  border: 2px solid black;
}
.btnSelectedPaymentMethod:focus, .btnSelectedPaymentMethod.focus{
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0);

}
.food-navigation .btn {
  width: 88px !important;
  /* height: 102px !important; */
  height: 70px !important;
  margin-bottom: 9px !important;
}
.food-navigation .btn img{
  height: 30px;
}
/*owl setting*/
.kitchen-item-box .owl-nav {
  position: absolute;
  bottom: 100%;
  left: 50%;
  right: 10%;
}
.kitchen-item-box .owl-theme .owl-nav [class*="owl-"] {
  background: transparent;
  padding: 2px 85px;
}
.food-menus .owl-nav {
  /* position: absolute; */
  bottom: 28px;
  right: 13%;
}
.food-menus .owl-theme .owl-nav [class*="owl-"] {
  background: transparent;
  padding: 0 60px;
}
.owl-carousel .owl-item img {
  display: inline;
  width: auto;
}
.kitchen-item-body ul li {
  line-height: 18px;
}
.kitchen-item-body ul li {
  text-transform: uppercase;
}
.notepad-button {
  /* right: 0;
  bottom: 32px;
  width: 103px;
  z-index: 99; */
}
.btnCash{
  margin-top:30px;
}
.mt-10{
  margin-top: 10px !important;
}
.mb-10{
  margin-bottom:10px !important;
}
.mt-5{
  margin-top: 5px !important;
}
.mb-5{
  margin-bottom:5px !important;
}
.pt-10{
  padding-top: 10px !important;
}
.pb-10{
  padding-bottom:10px !important;
}
.pt-5{
  padding-top: 5px !important;
}
.pb-5{
  padding-bottom:5px !important;
}
.no-col-pad{
  padding-left: 0px;
  padding-right: 0px;
}
.BrainhubCarouselItem{
  align-items: inherit !important;
}
hr {
  -moz-border-bottom-colors: none;
  -moz-border-image: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #FFFFFF;
  border-style: solid none;
  border-width: 2px 0;
  margin: 18px 0;
}
.btnActiveOrderType{
  background-color: #c82333;
  border-color: #bd2130;
}
.colCategory.item .activeMenuCategory{
  background-color: #0062cc;
  border-color: #005cbf;
}
.btnActiveMenuCategoryItem{
  background-color: #bd2130;
  /* border-color: #b21f2d; */
  border-color: #fff !important;
  border: 1px solid;
}
.btnActiveMenuItemComboItem{
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btnActiveIngredientType,.btn-secondary.btnActiveIngredientType:focus{
  /* border-color: #3d3d3d; */
  background-color: #3d3d3d;
}
.btnNotPadModalButton,.btnCustomerModalButton{
  background-color: yellow;
  color: #333;
  font-weight: bold;
}
.btn-primary-original{
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary-original:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.cursor-pointer{
  cursor: pointer;
}
img.trash-icon{
  margin-top: -6px;
  margin-left: 5px;
}
.food-info-list{
  margin-top: 5px;
}

img#loader-image {
  position: fixed;
  z-index: 999999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.font-weight-bolder{
  font-weight:bolder;
}
.invalid-feedback-error {
  display: block;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}
span.menu-item-name,span.menu-item-combo-item-name,span.menu-item-ingredient,span.combo-item-ingredient{
  cursor: pointer;
}
span.menu-item-name.selected,span.menu-item-combo-item-name.selected,span.menu-item-ingredient.selected,span.combo-item-ingredient.selected{
  background: yellow;
}
div.menu-item-name,div.menu-item-combo-item-name,div.menu-item-ingredient,div.combo-item-ingredient{
  cursor: pointer;
}
div.menu-item-name.selected,div.menu-item-combo-item-name.selected,div.menu-item-ingredient.selected,div.combo-item-ingredient.selected{
  background: yellow;
}
.ingredient div.menu-item-ingredient{
  width:70%;
  padding-left: 0px;
}
.ingredient div.menu-item-ingredient-price{
  width:30%;
  right: 0;
  text-align: right;
  top: 0;
}
.ingredient div.combo-item-ingredient{
  width:70%;
  padding-left: 0px;
}
.ingredient div.combo-item-ingredient-price{
  width:30%;
  right: 0;
  text-align: right;
  top: 0;
}
.pac-container {
  z-index: 10000 !important;
}
.react-autosuggest__input {
  width: 100%;
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.mt10{
  margin-top: 10px;
}
.mt20{
  margin-top: 20px;
}
.mt30{
  margin-top: 30px;
}
.mb10{
  margin-bottom: 10px;
}
.mb20{
  margin-bottom: 20px;
}
.mb30{
  margin-bottom: 30px;
}
.pb20{
  padding-bottom: 20px;
}
.no-row-margin{
  margin-right: 0px;
  margin-left: 0px;
}
.bs1{
  border: 1px solid black;
}

.PosOrdersPage .food-cart-head{
  height: 275px;
}
.homepage-main .btnMenu{
  height: inherit !important;
  font-size: 23.93px;
  line-height: 20px;
  text-transform: uppercase;
  /* padding-bottom: 5px; */
  padding-bottom: 0px;
  margin-bottom: 14px;
  text-align: left;
  padding-left: 8px;
  background: none;
  color: #fff;
}
.txtChangeAmountModal{
  font-size: 42px;
}
.txtChangeAmount{
  font-size: 62px;
}
.rc-time-picker-panel-select{
  font-size: 16px !important;
}
.btnLoadMoreKitchenOrders{
  height: inherit;
}
.no-bold-notes{
  font-weight: normal !important;
}
.btnKioskOrderSlection{
  background-color: #007aff;
  font-size: 26px !important; 
}
/* .btn-warning:not(:disabled):not(.disabled):active{
  color: #212529;
  background-color: #212529;
  border-color: #212529;
} */

.flash-button{
	background:blue;
	padding:5px 10px;
	color:#fff;
	border:none;
	border-radius:5px;
	
	animation-name: flash;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;

	/* //Firefox 1+ */
	-webkit-animation-name: flash;
	-webkit-animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;

	/* //Safari 3-4 */
	-moz-animation-name: flash;
	-moz-animation-duration: 1s;
	-moz-animation-timing-function: linear;
	-moz-animation-iteration-count: infinite;
}

@keyframes flash {  
    0% { opacity: 1.0; }
    50% { opacity: 0.5; }
    100% { opacity: 1.0; }
}

/* //Firefox 1+ */
@-webkit-keyframes flash {  
    0% { opacity: 1.0; }
    50% { opacity: 0.5; }
    100% { opacity: 1.0; }
}

/* //Safari 3-4 */
@-moz-keyframes flash {  
    0% { opacity: 1.0; }
    50% { opacity: 0.5; }
    100% { opacity: 1.0; }
}

#focustext {
  border: none;
  height: 0px;
  width: 0px;
}
.no-right-padding{
  padding-right: 0px !important;
}
.btnUpperMenu{
  min-width: 132px !important;
}
.NoInternetModal .modal-header h5{
  color:#333;
}
.NoInternetModal .modal-body p{
  color:#333;
}

/* Print Receipt */
#invoice-POS{ margin: 0; padding: 0; font-family: Arial; }
/* #invoice-POS{ margin: 0; padding: 0; font-family: tahoma; } */
#invoice-POS {
    /* box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5); */
    padding: 2mm;
    margin: 0 auto;
    /* width: 54mm; */
    /* width: 100%; */
    /* width: 110mm; */
    width: 110mm;
    /*width: 44mm;*/
    background: #FFF;
}

#invoice-POS ::selection {
    background: #f31544;
    color: #FFF;
}

#invoice-POS ::moz-selection {
    background: #f31544;
    color: #FFF;
}

#invoice-POS h1 {
    font-size: 22px;
    /* font-size: 1.5em; */
    color: #222;
}

#invoice-POS h2 {
    font-size: 22px;
    /* font-size: 16px; */
    font-weight: bolder;
    font-family: Arial;
    /* font-weight: normal; */
    /* font-size: .9em; */
}
#invoice-POS h2.resturantName {
    /* font-weight: bolder; */
    font-weight: normal;
}

#invoice-POS table h2 {
    font-size: 22px;
    /* font-size: 16px; */
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Arial;
    /* font-size: .9em; */
}
#invoice-POS h2.OrderNo {
    /* font-weight: bolder; */
    font-weight: normal;
}
#invoice-POS .CustomerPhoneNo{
  font-weight: normal;
}
#invoice-POS .OrderNotes{
  font-weight: normal;
  font-size: 32px;
}
#invoice-POS .CustomerName{
  text-transform: capitalize;
  font-size: 40px;
  font-family: Arial;
}
#invoice-POS p.OrderDelivery{
  text-transform: uppercase;
  font-size: 40px;
}
#invoice-POS .OrderCompletionTime{
  font-size: 40px;
}


#invoice-POS h3 {
    font-size: 1.2em;
    font-weight: 300;
    line-height: 2em;
}

#invoice-POS p {
    font-size: 22px;
    /* font-size: 16px; */
    font-weight: bolder;
    /* font-size: .7em; */
    color: #333;
    /* color: #666; */
    line-height: 1.2em;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Arial;
}

#invoice-POS #top,
#invoice-POS #mid,
#invoice-POS #bot {
    /* Targets all id with 'col-' */
    /* border-bottom: 1px solid #EEE; */
}

#invoice-POS #top {
    min-height: 100px;
}

#invoice-POS #mid {
    min-height: 80px;
}

#invoice-POS #bot {
    min-height: 50px;
    margin-top: 20px;
}

#invoice-POS #top .logo {
    height: 60px;
    width: 60px;
    background: url(http://jubzi.com/images/logo1.png) no-repeat;
    background-size: 60px 60px;
}

#invoice-POS .clientlogo {
    float: left;
    height: 60px;
    width: 60px;
    background: url(http://jubzi.com/images/client.jpg) no-repeat;
    background-size: 60px 60px;
    border-radius: 50px;
}

#invoice-POS .info {
    display: block;
    margin-left: 0;
}

#invoice-POS .title {
    float: right;
}

#invoice-POS .title p {
    text-align: right;
}

#invoice-POS table {
    width: 100%;
    border-collapse: collapse;
}

#invoice-POS .tabletitle {
    /* font-size: .5em; */
    /* background: #EEE; */
}

#invoice-POS .service {
    /* border-bottom: 1px solid #EEE; */
}

#invoice-POS .item {
    /* width: 24mm; */
}

#invoice-POS .itemtext {
    font-size: 22px;
    /* font-size: 16px; */
    /* font-weight: bolder; */
    margin-top: 0px;
    margin-bottom: 0px;
    /* font-size: .5em; */
}
#invoice-POS .tabletitle.MainItem td{
    padding-top: 5px; padding-bottom:5px;
}
#invoice-POS .serviceSub.serviceSubTr td {
    padding-top: 5px; padding-bottom:5px;
}
#invoice-POS .itemtext.itemTextName{
    padding-left: 10px;
}
#invoice-POS .serviceSub .itemtext.itemTextName{
    padding-left: 20px;
}
#invoice-POS .serviceSub .itemtext.itemTextNameSub{
    padding-left: 30px;
}
#invoice-POS .td.Price{
    text-align: right;
}
#invoice-POS #legalcopy {
    margin-top: 5mm;
}
.mt20{
    margin-top:20px !important;
}
#invoice-POS .service .tableitem p.itemtext.itemTextName.ingName{
  font-weight: normal !important;
}
#invoice-POS .service .tableitem p.itemtext.ingPrice{
  font-weight: normal !important;
}

#invoice-POS .serviceSub .tableitem p.itemtext.itemTextNameSub.ingName{
  font-weight: normal !important;
}
#invoice-POS .serviceSub .tableitem p.itemtext.itemTextNameSub.ingPrice{
  font-weight: normal !important;
}

#invoice-POS .txtOrderTabletNotPaid,.txtOrderTabletNotPaid{
  background: #000;
  background-color: #000;
  box-shadow: inset 0 0 0 1000px #000;
  color: #fff !important;
  margin-bottom: 10px;
}
#invoice-POS .txtOrderTabletNotPaid td{
  color: #fff !important;
  box-shadow: inset 0 0 0 1000px #000;
  font-size: 30px;
}
.slick-track{
  /* width: 100% !important; */
}
.print{
  display: none !important;
}
@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }
  * { margin: 0; padding: 0; font-family: Arial; }
  /* * { margin: 0; padding: 0; font-family: tahoma; } */
  .no-print{
    display: none !important;
  }
  .Toastify{
    display: none !important;
  }
  .print{
    display: block !important;
  }
#invoice-POS {
    /* box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5); */
    /* padding: 2mm; */
    padding-left: 25px;
    padding-right: 25px;
    margin: 0 auto;
    /* width: 54mm; */
    width: 110mm;
    /* width: 114mm; */
    /*width: 44mm;*/
    background: #FFF;
}

#invoice-POS ::selection {
    background: #f31544;
    color: #FFF;
}

#invoice-POS ::moz-selection {
    background: #f31544;
    color: #FFF;
}

#invoice-POS h1 {
    font-size: 16px;
    /* font-size: 1.5em; */
    color: #222;
}

#invoice-POS h2 {
    font-size: 18px;
    /* font-size: 16px; */
    font-weight: bolder;
    font-family: Arial;
    /* font-weight: normal; */
    /* font-size: .9em; */
}
#invoice-POS h2.resturantName {
    font-weight: bolder;
}
#invoice-POS table h2 {
    font-size: 18px;
    /* font-size: 16px; */
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Arial;
    /* font-size: .9em; */
}

#invoice-POS h3 {
    font-size: 1.2em;
    font-weight: 300;
    line-height: 2em;
}

#invoice-POS p {
    font-size: 18px;
    /* font-size: 16px; */
    font-weight: bolder;
    /* font-size: .7em; */
    color: #333;
    /* color: #666; */
    line-height: 1.2em;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Arial;
}

#invoice-POS #top,
#invoice-POS #mid,
#invoice-POS #bot {
    /* Targets all id with 'col-' */
    /* border-bottom: 1px solid #EEE; */
}

#invoice-POS #top {
    min-height: 100px;
}

#invoice-POS #mid {
    min-height: 80px;
}

#invoice-POS #bot {
    min-height: 50px;
    margin-top: 20px;
}

#invoice-POS #top .logo {
    height: 60px;
    width: 60px;
    background: url(http://jubzi.com/images/logo1.png) no-repeat;
    background-size: 60px 60px;
}

#invoice-POS .clientlogo {
    float: left;
    height: 60px;
    width: 60px;
    background: url(http://jubzi.com/images/client.jpg) no-repeat;
    background-size: 60px 60px;
    border-radius: 50px;
}

#invoice-POS .info {
    display: block;
    margin-left: 0;
}

#invoice-POS .title {
    float: right;
}

#invoice-POS .title p {
    text-align: right;
}

#invoice-POS table {
    width: 100%;
    border-collapse: collapse;
}

#invoice-POS .tabletitle {
    /* font-size: .5em; */
    /* background: #EEE; */
}

#invoice-POS .service {
    /* border-bottom: 1px solid #EEE; */
}

#invoice-POS .item {
    /* width: 24mm; */
}

#invoice-POS .itemtext {
    font-size: 18px;
    /* font-size: 16px; */
    /* font-weight: bolder; */
    margin-top: 0px;
    margin-bottom: 0px;
    /* font-size: .5em; */
}
#invoice-POS .tabletitle.MainItem td{
    padding-top: 5px; padding-bottom:5px;
}
#invoice-POS .serviceSub.serviceSubTr td {
    padding-top: 5px; padding-bottom:5px;
}
#invoice-POS .itemtext.itemTextName{
    padding-left: 10px;
}
#invoice-POS .serviceSub .itemtext.itemTextName{
    padding-left: 20px;
}
#invoice-POS .serviceSub .itemtext.itemTextNameSub{
    padding-left: 30px;
}
#invoice-POS .td.Price{
    text-align: right;
}
#invoice-POS #legalcopy {
    margin-top: 5mm;
}
.mt20{
    margin-top:20px !important;
}
#invoice-POS .txtOrderTabletNotPaid{
  background: #000;
  background-color: #000;
  box-shadow: inset 0 0 0 1000px #000;
  color: #fff !important;
  margin-bottom: 10px;
}
#invoice-POS .txtOrderTabletNotPaid td{
  color: #fff !important;
  box-shadow: inset 0 0 0 1000px #000;
  font-size: 30px;
}
    .noprint, .noprint * { display: none; }
    @page { margin: 0 }
    /* body  { margin: 1.0cm;margin-left: -0.5cm; } */
    /* body  { margin: 1.0cm;margin-left: -0.5cm; } */
    #invoice-POS h2 {
        font-size: 22px;
        /* font-size: 22px; */
        font-weight: bolder;
        /* font-weight: normal; */
        /* font-size: .9em; */
    }
    #invoice-POS table h2 {
        font-size: 22px;
        /* font-size: 22px; */
        margin-top: 0px;
        margin-bottom: 0px;
        /* font-size: .9em; */
    }
    #invoice-POS p {
        font-size: 22px;
        /* font-size: 22px; */
        font-weight: bolder;
        /* font-size: .7em; */
        color: #333;
        /* color: #666; */
        line-height: 1.2em;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    #invoice-POS .itemtext {
        font-size: 22px;
        /* font-size: 18px; */
        /* font-weight: bolder; */
        margin-top: 0px;
        margin-bottom: 0px;
        
        /* font-size: .5em; */
    }
    


}
@page {
    /* margin:50px auto 50px;
   counter-increment: page;
   counter-reset: page 1;
   @top-right {
       content: "Page " counter(page) " of " counter(pages);
   } */

}
